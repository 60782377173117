import CouponModal from "../../organisms/Modal/CouponModal/CouponModal.component";
import CheckPointSQLController from "./CheckPointSQL.controller";
import "./CheckPointSQL.styles.scss";
import {
  ButtonDS,
  CustomModal,
  GlobalIcon,
  IconNameT,
} from "get-life-storybook-ts";
import numberFormat from "../../../../utils/numberFormat";
import { GuaranteeI } from "../../../../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import OtpModal from "../../../projects/OtpModal";
import DownloadableFilesModalDS from "../../../components/DownloadableFiles/DownloadableFilesModalDS";
import EditSQLProject from "../../../projects/editProjectsSQL/EditSQLProject";

interface Props {
  itemLabel?: string;
  icon?: IconNameT | null;
  label?: boolean;
  className?: any;
  divClassName?: any;
}

interface PropsGuarantee {
  itemLabel?: string;
  icon?: IconNameT | null;
  label?: boolean;
  className?: any;
  divClassName?: any;
  capital?: GuaranteeI | undefined;
}

export const AtomItem = ({
  itemLabel = "Item label",
  icon = null,
  label = true,
  className = "",
  divClassName = "",
}: Props): JSX.Element => {
  return (
    <div className={`inline-flex items-center gap-2 relative ${className}`}>
      {icon && (
        <GlobalIcon iconName={icon} size="XS" color="var(--primary-color)" />
      )}

      {label && (
        <div
          className={`relative w-fit mt-[-1.00px] BodyM text-[#424242] ${divClassName}`}
        >
          {itemLabel}
        </div>
      )}
    </div>
  );
};

const CheckPointSQL = (): JSX.Element => {
  const {
    capitals,
    promotion,
    prices,
    setDownloadableOpen,
    downloadableOpen,
    contract,
    fetchPromo,
    handleClickIcon,
    setShowModalCoupon,
    showModalCoupon,
    translate,
    validateCoupon,
    downloadableFiles,
    showModal,
    setShowModal,
    modalData,
    handlePreviousModal,
    previousModal,
  } = CheckPointSQLController();

  const RenderGuarantee = ({
    itemLabel = "",
    icon = null,
    capital = undefined,
    className = "",
  }: PropsGuarantee): JSX.Element => {
    return (
      <div
        className={`flex items-center gap-4 pl-[18px] pr-0 py-0 relative self-stretch w-full flex-[0_0_auto] ${className}`}
      >
        <AtomItem
          className="!flex-1 !flex !grow"
          divClassName="!flex-1 ![white-space:unset] !w-[unset]"
          itemLabel={itemLabel}
          icon={icon}
        />

        {capital && capital.active && (
          <div className="relative w-fit mt-[-1.00px] BodyL font-light text-[#555]">
            {numberFormat(capital.capital, "capital")}
          </div>
        )}
        {capital && !capital.active && capital.available && (
          <div
            className="relative w-fit mt-[-1.00px] BodyL font-light text-purple font-bold pointer"
            onClick={() => handlePreviousModal()}
          >
            {translate("checkpoint.multiproduct.add")}
          </div>
        )}
        {capital && !capital.active && !capital.available && (
          <div className="relative w-fit mt-[-1.00px] BodyL font-light text-[#555]">
            {translate("checkpoint.multiproduct.notAvailable")}
          </div>
        )}
        {!capital && (
          <div className="relative w-fit mt-[-1.00px] BodyL font-light text-[#555]"></div>
        )}
      </div>
    );
  };

  return (
    <>
      <CouponModal
        couponCode={promotion?.promotionalCode}
        fetchPromo={fetchPromo}
        showModal={showModalCoupon}
        setShowModal={setShowModalCoupon}
        translate={translate}
        validateCoupon={validateCoupon}
      />
      <DownloadableFilesModalDS
        open={downloadableOpen}
        setOpen={setDownloadableOpen}
        downloadablePdfs={downloadableFiles}
      />

      <EditSQLProject
        modalData={modalData!}
        setShowModal={setShowModal}
        showModal={showModal}
        isEditFromCheckPoint={true}
      />

      <CustomModal
        ref={previousModal}
        ModalTitle={
          <div className="H4 cardsBroker-modalTitle">
            <GlobalIcon iconName="InfoIcon" size="XS" color="currentColor" />
            {translate("checkpoint.previousModal.title")}
          </div>
        }
        ModalBody={
          <div className="flex flex-col gap-4">
            <div>
              <p
                className="BodyL font-bold"
                dangerouslySetInnerHTML={{
                  __html: translate("checkpoint.previousModal.body.p1"),
                }}
              />
            </div>
            <div>
              <p
                className="BodyL"
                dangerouslySetInnerHTML={{
                  __html: translate("checkpoint.previousModal.body.p5"),
                }}
              />
            </div>

            <div className="flex flex-row gap-4 pt-4">
              <ButtonDS
                label={translate("checkpoint.previousModal.button.cancel")}
                onClick={() => handlePreviousModal()}
                buttonType="secondary"
                size="50"
                className="flex-1"
              />
              <ButtonDS
                label={translate("checkpoint.previousModal.button.confirm")}
                onClick={() => {
                  handlePreviousModal();
                  setShowModal(true);
                }}
                size="50"
                className="flex-1"
              />
            </div>
          </div>
        }
      />

      <div className="flex flex-col items-center gap-2.5 px-10 py-8 relative bg-[#ECF0FF] rounded-[0px_0px_16px_16px] border-r [border-right-style:solid] border-b [border-bottom-style:solid] border-l [border-left-style:solid] border-life-blue50">
        <div className="flex flex-col w-[1280px] items-center gap-4 relative flex-[0_0_auto]">
          <div className="flex items-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
            <div className="inline-flex items-center gap-4 relative flex-[0_0_auto]">
              <GlobalIcon
                className="!relative !w-6 !h-6"
                iconName={"FileTextIcon"}
                size="XS"
                color="var(--primary-color)"
              />
              <div className="relative w-fit mt-[-1.00px] BodyL font-bold text-[#3430e3]">
                {translate("checkpoint.title.resume")}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-center gap-8 px-8 py-6 relative self-stretch w-full flex-[0_0_auto] bg-white rounded-[10px]">
            <div className="flex items-center gap-20 relative self-stretch w-full">
              <div className="inline-flex flex-col items-start gap-9 relative self-stretch flex-[0_0_auto]">
                <div className="flex flex-col w-[338px] items-start gap-3 relative flex-[0_0_auto]">
                  <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="inline-flex items-center gap-4 relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] BodyM font-bold text-[#424242]">
                        {translate(
                          "checkpoint.multiproduct.mandatoryBasicGuarantee"
                        )}
                      </div>
                    </div>
                  </div>
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.death")}
                    icon={"ChurchCrossIcon"}
                    capital={capitals?.DEATH}
                  />
                </div>
                <div className="flex flex-col w-[338px] items-start gap-3 relative flex-[0_0_auto]">
                  <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="inline-flex items-center gap-4 relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] BodyM font-bold text-[#424242]">
                        {translate(
                          "checkpoint.multiproduct.complementsGuarantee"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                    <RenderGuarantee
                      itemLabel={translate(
                        "checkpoint.multiproduct.disability"
                      )}
                      icon={"DisabledIcon"}
                      capital={capitals?.DISABILITY}
                    />
                    <RenderGuarantee
                      itemLabel={translate(
                        "checkpoint.multiproduct.temporaryDisability"
                      )}
                      icon={"DisabledIcon"}
                      capital={capitals?.TEMPORARY_DISABILITY}
                    />
                    <RenderGuarantee
                      itemLabel={translate(
                        "checkpoint.multiproduct.seriousIllness"
                      )}
                      icon={"WiderUmbrellaIcon"}
                      capital={capitals?.SERIOUS_ILLNESS}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[338px] items-start gap-3 relative self-stretch">
                {/* <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="inline-flex items-center gap-4 relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] BodyM font-bold text-[#424242]">
                      {translate("checkpoint.multiproduct.incidentalGuarantee")}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-4 px-3.5 py-3 relative self-stretch w-full flex-[0_0_auto] bg-[#f5f7fac7] rounded-[10px]">
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.death")}
                    icon={"ChurchCrossIcon"}
                    capital={undefined}
                    className={"pl-0"}
                  />
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.accident")}
                    icon={"CrutchesIcon"}
                    capital={capitals?.ACCIDENT_DEATH}
                  />
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.trafficAccident")}
                    icon={"CarCrashIcon"}
                    capital={capitals?.TRAFFIC_ACCIDENT_DEATH}
                  />
                </div>
                <div className="flex flex-col items-start gap-4 px-3.5 py-3 relative self-stretch w-full flex-[0_0_auto] bg-[#f5f7fac7] rounded-[10px]">
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.category.disability")}
                    icon={"DisabledIcon"}
                    capital={undefined}
                    className={"pl-0"}
                  />
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.accident")}
                    icon={"CrutchesIcon"}
                    capital={capitals?.ACCIDENT_DISABILITY}
                  />
                  <RenderGuarantee
                    itemLabel={translate("checkpoint.multiproduct.trafficAccident")}
                    icon={"CarCrashIcon"}
                    capital={capitals?.TRAFFIC_ACCIDENT_DISABILITY}
                  />

                </div> */}
              </div>
              <div className="inline-flex flex-col items-center justify-center gap-[18px] px-4 py-0 relative self-stretch flex-[0_0_auto] mr-[-1.00px] bg-[#ECF0FF] rounded-lg">
                <div className="relative w-fit BodyL font-bold text-[#424242]">
                  {translate("checkpoint.finalPrice")}
                </div>
                <div className="inline-flex items-start gap-9 px-0 py-[19px] relative flex-[0_0_auto]">
                  <div className="inline-flex flex-col items-center gap-2 relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] BodyS font-light text-[#7B7B7B]">
                      <p
                        className="BodyM"
                        dangerouslySetInnerHTML={{
                          __html: promotion?.beforePromoText || "",
                        }}
                      />
                    </div>
                    <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                      <div className="H2 text-purple">
                        {numberFormat(prices?.monthly.promoted, "price")}
                      </div>
                      <div className="BodyS text-purple">
                        {translate("card.period.monthly")}
                      </div>
                    </div>
                    <div className="relative w-fit BodyS font-light text-[#7B7B7B]">
                      <p
                        className="BodyM"
                        dangerouslySetInnerHTML={{
                          __html: promotion?.afterPromoText || "",
                        }}
                      />
                    </div>
                  </div>
                  <div className="inline-flex flex-col items-center justify-center gap-2 relative self-stretch flex-[0_0_auto]">
                    <div className="inline-flex flex-col items-start justify-center gap-1 relative flex-[0_0_auto]">
                      {promotion?.promotionalCode ? (
                        <>
                          <div className="BodyS font-light text-[#7B7B7B]">
                            {translate("checkpoint.promotion.applied")}
                          </div>
                          <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                            <div className="H2 text-purple">
                              {numberFormat(prices?.yearly.promoted, "price")}
                            </div>
                            <div className="BodyS text-purple">
                              {translate("card.period.yearly")}
                            </div>
                          </div>
                          <div className="relative w-fit BodyS font-light text-[#7B7B7B] line-through">
                            {numberFormat(prices?.yearly.real, "price")} €
                            {translate("card.period.yearly")}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                            <div className="H2 text-purple">
                              {numberFormat(prices?.yearly.real, "price")}
                            </div>
                            <div className="BodyS text-purple">
                              {translate("card.period.yearly")}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-10 relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-center justify-center px-4 py-3 relative flex-1 grow rounded-[100px]">
                <div
                  className="inline-flex items-center justify-center gap-2 relative flex-[0_0_auto] pointer"
                  onClick={() => handleClickIcon()}
                >
                  <GlobalIcon
                    className="!relative !w-6 !h-6"
                    iconName="Tag2Icon"
                  />
                  <div className="relative w-fit mt-[-1.00px] BodyL text-[#3430e3] text-center underline">
                    {promotion?.promotionalCode
                      ? translate("checkpoint.promotion.modify")
                      : translate("checkpoint.promotion.add")}
                  </div>
                </div>
              </div>

              <ButtonDS
                leftIcon="FileDownload"
                label={translate("checkpoint.documents.download")}
                onClick={() => setDownloadableOpen(true)}
                size="50"
                className="flex-1"
                buttonType="secondary"
              />
              <ButtonDS
                rightIcon="ArrowRightIcon"
                label={translate("checkpoint.button.contract")}
                onClick={() => contract()}
                size="50"
                className="flex-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-16 px-20 gap-6">
        <ButtonDS
          label={translate("checkpoint.button.edit")}
          onClick={() => handlePreviousModal()}
          buttonType="tertiary"
          size="50"
          leftIcon="EditIcon"
        />
      </div>
    </>
  );
};

export default CheckPointSQL;
