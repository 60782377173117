import "./modalSendOtp.scss";
import { ButtonDS, InputDS, ModalDS } from "get-life-storybook-ts";

export default function ModalSendOtp(props) {
  const { showModal, setShowModal, sendEmail, paymentMethod, setReceiver, receiver, translate } = props;

  return (
    <ModalDS
      open={showModal}
      onClose={() => setShowModal(false)}
      icon=""
      title={`${translate("modal.payment.send.title")} ${paymentMethod}`}
      content={
        <>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <span className="BodyM font-regular text-[#3430E3]">{paymentMethod === 'email' ? translate("modal.payment.send.subtitle1.email") : translate("modal.payment.send.subtitle1.phone")}</span>
              <span className="BodyM font-regular text-[#424242]">{paymentMethod === 'email' ? translate("modal.payment.send.subtitle2.email") : translate("modal.payment.send.subtitle2.phone")}</span>
            </div>

            <InputDS
              onChange={(e) => setReceiver(e.target.value)}
              type={paymentMethod === 'email' ? "email" : "number"}
              value={receiver}
              label={paymentMethod === 'email' ? translate("modal.payment.label.email") : translate("modal.payment.label.phone")}
            ></InputDS>
          </div>
        </>
      }
      buttons={
        <>
          <ButtonDS buttonType="secondary" onClick={() => setShowModal(false)} label={translate("modal.sendPdf.button.cancel")} size="40" />
          <ButtonDS onClick={() => sendEmail()} label={translate("modal.sendPdf.button.send")} disabled={!receiver} size="40" />
        </>
      }
    />

  );
}
