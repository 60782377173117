import {
  ButtonDS,
  DrawerDS,
  GlobalIcon,
  IconButton,
  IconNameT,
  InputDS,
  TabsDS,
  TextareaDS,
  Toggle,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import { useContext } from "react";
import numberFormat, {
  formatPriceWithoutCurrency,
} from "../../../utils/numberFormat";
import { Accordion } from "../../dashboard/multiproduct/DashboardMultiproduct";
import EditSQLProjectController from "./EditSQLProject.controller";
import { ModalDataI } from "./../EditProjects.types";
import DownloadableFiles from "../../components/DownloadableFiles/DownloadableFiles";
import { getValidateEmail } from "../../../utils/staticData";
import { EnableOtpButton } from "../../components/TableLife5/constants/logicButtons";

interface EditSQLProjectI {
  modalData: ModalDataI;
  setShowModal: (value: boolean) => void;
  showModal: boolean | undefined;
  isEditFromCheckPoint?: boolean;
  fetchProjects?: (p: any) => void;
}

interface Tabs {
  label: string;
  icon: IconNameT;
  panel: React.ReactNode | string;
  callback?: () => void;
  isEditFromCheckPoint?: boolean;
}

const EditSQLProject = ({
  modalData,
  setShowModal,
  showModal,
  isEditFromCheckPoint = false,
  fetchProjects,
}: EditSQLProjectI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {
    basicBoundaries,
    basicForm,
    editBasicForm,
    guaranteeForm,
    toggleGuaranteeActive,
    updateGuaranteeCapital,
    rules,
    editMode,
    setEditMode,
    toggleOpen,
    open,
    sendEmail,
    setSendEmail,
    sendOTP,
    setSendOTP,
    email,
    setEmail,
    text,
    setText,
    loading,
    sendEmailDocument,
    basicFormError,
    saveChanges,
    downloadablePdfs,
    pricing,
    handleKeyDown,
    generateOTP,
    OTP,
    recoverProject,
    updateCheckpoint,
    setShowNotification,
  } = EditSQLProjectController(modalData, showModal!, fetchProjects);

  const prices = {
    isPromoted: pricing?.promotion.promotionalCode ? true : false,
    beforePromoText: pricing?.promotion.beforePromoText,
    afterPromoText: pricing?.promotion.afterPromoText,
    monthlyPrice: pricing?.prices.monthly.real,
    promotedMonthlyPrice: pricing?.prices.monthly.promoted,
    yearlyPrice: pricing?.prices.yearly.real,
    promotedYearlyPrice: pricing?.prices.yearly.promoted,
  };

  const DISABILITY = rules?.find((e) => e.trait === "DISABILITY");
  const TEMPORARY_DISABILITY = rules?.find(
    (e) => e.trait === "TEMPORARY_DISABILITY"
  );
  const SERIOUS_ILLNESS = rules?.find((e) => e.trait === "SERIOUS_ILLNESS");

  const isIPAActive = guaranteeForm["DISABILITY"].active;
  const isIPTActive = guaranteeForm["TEMPORARY_DISABILITY"].active;

  const drawerTitle = (() => {
    if (sendOTP) return "Link para el cliente";
    if (sendEmail) return "Documentos descargables";
    return translate("edit.project.title");
  })();

  const drawerIcon = (() => {
    if (sendOTP) return "LinkIcon";
    if (sendEmail) return "DownloadCloudIcon";
    return "PencilIcon";
  })();

  const PersonalTab: Tabs[] = [
    {
      label: translate("edit.project.personalData"),
      icon: "SimpleUserIcon",
      panel: (
        <>
          <Prices
            prices={prices}
            setSendEmail={setSendEmail}
            generateOTP={generateOTP}
            modalData={modalData}
          />
          <div className="p-[24px] !pb-[124px]">
            <div className="space-y-[24px]">
              <InputDS
                onKeyDownCapture={handleKeyDown}
                label={translate("edit.projects.referenceName.label")}
                icon="UserSearchIcon"
                placeholder={translate(
                  "edit.projects.referenceName.placeholder"
                )}
                value={basicForm.referenceName}
                onChange={(e) => editBasicForm("referenceName", e.target.value)}
                error={basicFormError.referenceName}
              />
              <InputDS
                onKeyDownCapture={handleKeyDown}
                type="date"
                label={translate("edit.project.input.birthdate.label")}
                icon="CalendarEventLineIcon"
                disabled={true}
                value={basicForm.birthDate}
              />
              <InputDS
                onKeyDownCapture={handleKeyDown}
                label={translate("edit.projects.phone.label")}
                icon="DeviceMobile"
                placeholder={translate("edit.projects.phone.placeholder")}
                value={basicForm.phone}
                onChange={(e) => editBasicForm("phone", e.target.value)}
                error={basicFormError.phone}
              />
              <InputDS
                onKeyDownCapture={handleKeyDown}
                label={translate("edit.projects.email.label")}
                icon="MailIcon"
                placeholder={translate("edit.projects.email.placeholder")}
                value={getValidateEmail(basicForm.email)}
                onChange={(e) => editBasicForm("email", e.target.value)}
                error={basicFormError.email}
              />
            </div>
          </div>
          <div className="pt-[20px] px-[24px] pb-[24px] fixed bottom-0 flex flex-row gap-[16px] w-full bg-white border-t border-t-[#ECECEC]">
            {!isEditFromCheckPoint && (
              <ButtonDS
                label={translate("edit.project.button.submit")}
                leftIcon="CheckedIcon"
                buttonType="secondary"
                className="flex-1"
                onClick={() => saveChanges()}
              />
            )}
            <ButtonDS
              label={translate("tables.buttons.recuperar")}
              rightIcon="ArrowRightIcon"
              className="flex-1"
              onClick={() => recoverProject()}
            />
          </div>
        </>
      ),
    },
  ];

  const CapitalTab: Tabs[] = [
    {
      label: translate("edit.project.product"),
      icon: "CashIcon",
      panel: (
        <>
          <Prices
            prices={prices}
            setSendEmail={setSendEmail}
            generateOTP={generateOTP}
            modalData={modalData}
          />
          <div className="p-[24px] !pb-[124px]">
            <div className="flex items-center justify-between mb-[8px]">
              <span className="text-[#424242] BodyM font-bold">
                {translate("dashboard.multiproduct.mandatoryBasicGuarantee")}
              </span>
              <button
                className="p-[8px]"
                onClick={() => toggleOpen("basic")}
                style={{
                  transform: open["basic"] ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <GlobalIcon iconName="ChevronDown" color="currentColor" />
              </button>
            </div>
            <Accordion height={400} open={open.basic}>
              <div className="flex flex-col gap-[24px]">
                <div className="flex flex-row gap-[8px] items-center">
                  <GlobalIcon
                    iconName="Cross2Icon"
                    size="XS"
                    className="text-[var(--dark-primary-color)]"
                  />
                  <span className="BodyM font-medium text-[#424242] mr-auto">
                    {translate("dashboard.multiproduct.mandatoryDecease")}
                  </span>
                  <span className="BodyM font-bold text-[var(--dark-primary-color)]">
                    {numberFormat(guaranteeForm["DEATH"].capital, "capital")}
                  </span>
                  <IconButton
                    icon="PencilIcon"
                    size="32"
                    buttonType="secondary"
                    onClick={() => setEditMode(true)}
                    disabled={editMode}
                  />
                </div>
                {editMode && (
                  <InputDS
                    onKeyDownCapture={handleKeyDown}
                    type="number"
                    label={translate("dashboard.multiproduct.capital.label")}
                    placeholder={translate(
                      "dashboard.multiproduct.capital.placeholder"
                    )}
                    name="basicCapital"
                    icon="CurrencyEuroIcon"
                    onKeyDown={(e) => {
                      if (e.key === "," || e.key === ".") e.preventDefault();
                    }}
                    helper={
                      basicBoundaries.max && basicBoundaries.min
                        ? `${translate(
                            "dashboard.multiproduct.minimum"
                          )} ${new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 0,
                          }).format(basicBoundaries.min)}, ${translate(
                            "dashboard.multiproduct.maximum"
                          )} ${new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 0,
                          }).format(basicBoundaries.max)}`
                        : undefined
                    }
                    step={5000}
                    value={guaranteeForm["DEATH"].capital}
                    onChange={(e) => {
                      const capital = parseInt(e.target.value);
                      if (isNaN(capital)) return;
                      updateGuaranteeCapital("DEATH", capital);
                    }}
                    onBlur={() => {
                      let value = guaranteeForm.DEATH.capital;
                      if (value > basicBoundaries.max!) {
                        value = basicBoundaries.max!;
                      }
                      if (value < basicBoundaries.min!) {
                        value = basicBoundaries.min!;
                      }
                      updateGuaranteeCapital("DEATH", value, true);
                    }}
                  />
                )}
              </div>
            </Accordion>
            <div className="flex items-center justify-between mb-[8px]">
              <span className="text-[#424242] BodyM font-bold">
                {translate("edit.sql.project.complementaryGuarantees")}
              </span>
              <button
                className="p-[8px]"
                onClick={() => toggleOpen("additional")}
                style={{
                  transform: open["additional"]
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              >
                <GlobalIcon iconName="ChevronDown" color="currentColor" />
              </button>
            </div>
            <Accordion height={400} open={open.additional}>
              <div className="bg-[#F5F7FA] py-[8px] px-[12px] rounded-[8px] flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[12px]">
                  <div className="flex flex-row gap-[8px] items-center">
                    <GlobalIcon
                      iconName="DisabledIcon"
                      size="XS"
                      color={
                        isIPTActive ? "#A0A0A0" : "var(--dark-primary-color)"
                      }
                    />
                    <span
                      className="BodyM font-medium mr-auto flex-1 overflow-hidden text-ellipsis"
                      style={{
                        color: isIPTActive ? "#A0A0A0" : "#424242",
                      }}
                    >
                      {translate("edit.sql.project.ipa")}
                    </span>
                    {isIPTActive ? (
                      <span className="BodyM text-[#A0A0A0]">
                        No compatible
                      </span>
                    ) : guaranteeForm["DISABILITY"].capital ? (
                      <span className="BodyM font-bold text-[var(--dark-primary-color)]">
                        {numberFormat(
                          guaranteeForm["DISABILITY"].capital,
                          "capital"
                        )}
                      </span>
                    ) : (
                      <span className="BodyM text-[#A0A0A0]">No</span>
                    )}
                    {editMode ? (
                      <Toggle
                        initialValue={guaranteeForm.DISABILITY.active}
                        labels={[]}
                        onValueChange={() =>
                          toggleGuaranteeActive("DISABILITY")
                        }
                        disabled={
                          !DISABILITY?.available ||
                          guaranteeForm["TEMPORARY_DISABILITY"].active
                        }
                      />
                    ) : (
                      <IconButton
                        icon="PencilIcon"
                        size="32"
                        buttonType="secondary"
                        onClick={() => setEditMode(true)}
                        disabled={isIPTActive}
                      />
                    )}
                  </div>
                  {guaranteeForm["DISABILITY"].active && editMode && (
                    <InputDS
                      onKeyDownCapture={handleKeyDown}
                      type="number"
                      label={translate(
                        "dashboard.multiproduct.absoluteDisability.label"
                      )}
                      placeholder={"10.000"}
                      name="ipaCapital"
                      icon="CurrencyEuroIcon"
                      onKeyDown={(e) => {
                        if (e.key === "," || e.key === ".") e.preventDefault();
                      }}
                      helper={`${translate(
                        "dashboard.multiproduct.minimum"
                      )} ${new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 0,
                      }).format(DISABILITY?.min!)}, ${translate(
                        "dashboard.multiproduct.maximum"
                      )} ${new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 0,
                      }).format(DISABILITY?.max!)}`}
                      min={DISABILITY?.min}
                      max={DISABILITY?.max}
                      step={5000}
                      value={guaranteeForm["DISABILITY"].capital}
                      onChange={(e) => {
                        const capital = parseInt(e.target.value);
                        if (isNaN(capital)) return;
                        updateGuaranteeCapital("DISABILITY", capital);
                      }}
                      onBlur={() => {
                        let value = guaranteeForm["DISABILITY"].capital;
                        if (value > DISABILITY?.max!) {
                          value = DISABILITY?.max!;
                        }
                        if (value < DISABILITY?.min!) {
                          value = DISABILITY?.min!;
                        }
                        updateGuaranteeCapital("DISABILITY", value, true);
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-row gap-[8px] items-center">
                    <GlobalIcon
                      iconName="DisabledIcon"
                      size="XS"
                      color={
                        isIPAActive ? "#A0A0A0" : "var(--dark-primary-color)"
                      }
                    />
                    <span
                      className="BodyM font-medium mr-auto flex-1 overflow-hidden text-ellipsis"
                      style={{
                        color: isIPAActive ? "#A0A0A0" : "#424242",
                      }}
                    >
                      {translate("edit.sql.project.ipt")}
                    </span>
                    {isIPAActive ? (
                      <span className="BodyM text-[#A0A0A0]">
                        No compatible
                      </span>
                    ) : guaranteeForm["TEMPORARY_DISABILITY"].capital ? (
                      <span className="BodyM font-bold text-[var(--dark-primary-color)]">
                        {numberFormat(
                          guaranteeForm["TEMPORARY_DISABILITY"].capital,
                          "capital"
                        )}
                      </span>
                    ) : (
                      <span className="BodyM text-[#A0A0A0]">No</span>
                    )}
                    {editMode ? (
                      <Toggle
                        initialValue={guaranteeForm.TEMPORARY_DISABILITY.active}
                        labels={[]}
                        onValueChange={() =>
                          toggleGuaranteeActive("TEMPORARY_DISABILITY")
                        }
                        disabled={
                          !TEMPORARY_DISABILITY?.available ||
                          guaranteeForm["DISABILITY"].active
                        }
                      />
                    ) : (
                      <IconButton
                        icon="PencilIcon"
                        size="32"
                        buttonType="secondary"
                        onClick={() => setEditMode(true)}
                        disabled={isIPAActive}
                      />
                    )}
                  </div>
                  {guaranteeForm["TEMPORARY_DISABILITY"].active && editMode && (
                    <div className="mt-[12px]">
                      <InputDS
                        onKeyDownCapture={handleKeyDown}
                        type="number"
                        label={translate(
                          "dashboard.multiproduct.totalDisability.label"
                        )}
                        placeholder={"10.000"}
                        name="ipaCapital"
                        icon="CurrencyEuroIcon"
                        onKeyDown={(e) => {
                          if (e.key === "," || e.key === ".")
                            e.preventDefault();
                        }}
                        helper={`${translate(
                          "dashboard.multiproduct.minimum"
                        )} ${new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                          maximumFractionDigits: 0,
                        }).format(TEMPORARY_DISABILITY?.min!)}, ${translate(
                          "dashboard.multiproduct.maximum"
                        )} ${new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                          maximumFractionDigits: 0,
                        }).format(TEMPORARY_DISABILITY?.max!)}`}
                        min={TEMPORARY_DISABILITY?.min}
                        max={TEMPORARY_DISABILITY?.max}
                        step={5000}
                        value={guaranteeForm["TEMPORARY_DISABILITY"].capital}
                        onChange={(e) => {
                          const capital = parseInt(e.target.value);
                          if (isNaN(capital)) return;
                          updateGuaranteeCapital(
                            "TEMPORARY_DISABILITY",
                            capital
                          );
                        }}
                        onBlur={() => {
                          let value =
                            guaranteeForm["TEMPORARY_DISABILITY"].capital;
                          if (value > TEMPORARY_DISABILITY?.max!) {
                            value = TEMPORARY_DISABILITY?.max!;
                          }
                          if (value < TEMPORARY_DISABILITY?.min!) {
                            value = TEMPORARY_DISABILITY?.min!;
                          }
                          updateGuaranteeCapital(
                            "TEMPORARY_DISABILITY",
                            value,
                            true
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="text-[#8E8E8E] flex flex-row gap-[4px] items-center BodyS">
                    <GlobalIcon
                      iconName="InfoIcon"
                      color="currentColor"
                      size="XS"
                      className="w-[16px] h-[16px]"
                    />
                    <span>La IPA y la IPT son incompatibles.</span>
                  </div>
                </div>
                <div className="flex flex-col gap-[12px]">
                  <div className="flex flex-row gap-[8px] items-center">
                    <GlobalIcon
                      iconName="WiderUmbrellaIcon"
                      size="XS"
                      className="text-[var(--dark-primary-color)] basis-[24px]"
                    />
                    <span className="BodyM font-medium text-[#424242] mr-auto flex-1 overflow-hidden text-ellipsis">
                      {translate("edit.sql.project.seriousIllnesses")}
                    </span>
                    {guaranteeForm["SERIOUS_ILLNESS"].capital ? (
                      <span className="BodyM font-bold text-[var(--dark-primary-color)]">
                        {numberFormat(
                          guaranteeForm["SERIOUS_ILLNESS"].capital,
                          "capital"
                        )}
                      </span>
                    ) : (
                      <span className="BodyM text-[#A0A0A0]">No</span>
                    )}
                    {editMode ? (
                      <Toggle
                        initialValue={guaranteeForm.SERIOUS_ILLNESS.active}
                        labels={[]}
                        onValueChange={() =>
                          toggleGuaranteeActive("SERIOUS_ILLNESS")
                        }
                        disabled={!SERIOUS_ILLNESS?.available}
                      />
                    ) : (
                      <IconButton
                        icon="PencilIcon"
                        size="32"
                        buttonType="secondary"
                        onClick={() => setEditMode(true)}
                      />
                    )}
                  </div>
                  {guaranteeForm["SERIOUS_ILLNESS"].active && editMode && (
                    <InputDS
                      onKeyDownCapture={handleKeyDown}
                      type="number"
                      label={translate(
                        "dashboard.multiproduct.seriousIllnesses.label"
                      )}
                      placeholder={"10.000"}
                      name="ipaCapital"
                      icon="CurrencyEuroIcon"
                      onKeyDown={(e) => {
                        if (e.key === "," || e.key === ".") e.preventDefault();
                      }}
                      helper={`${translate(
                        "dashboard.multiproduct.minimum"
                      )} ${new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 0,
                      }).format(SERIOUS_ILLNESS?.min!)}, ${translate(
                        "dashboard.multiproduct.maximum"
                      )} ${new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 0,
                      }).format(SERIOUS_ILLNESS?.max!)}. ${translate(
                        "dashboard.multiproduct.seriousIllnesses.helper"
                      )}`}
                      min={SERIOUS_ILLNESS?.min}
                      max={SERIOUS_ILLNESS?.max}
                      step={5000}
                      value={guaranteeForm["SERIOUS_ILLNESS"].capital}
                      onChange={(e) => {
                        const capital = parseInt(e.target.value);
                        if (isNaN(capital)) return;
                        updateGuaranteeCapital("SERIOUS_ILLNESS", capital);
                      }}
                      onBlur={() => {
                        let value = guaranteeForm["SERIOUS_ILLNESS"].capital;
                        if (value > SERIOUS_ILLNESS?.max!) {
                          value = SERIOUS_ILLNESS?.max!;
                        }
                        if (value < SERIOUS_ILLNESS?.min!) {
                          value = SERIOUS_ILLNESS?.min!;
                        }
                        updateGuaranteeCapital("SERIOUS_ILLNESS", value, true);
                      }}
                    />
                  )}
                </div>
              </div>
            </Accordion>
          </div>
          <div className="pt-[20px] px-[24px] pb-[24px] fixed bottom-0 flex flex-row gap-[16px] w-full bg-white border-t border-t-[#ECECEC]">
            <ButtonDS
              label={
                !isEditFromCheckPoint
                  ? translate("tables.buttons.recuperar")
                  : translate("edit.project.button.submit")
              }
              rightIcon="ArrowRightIcon"
              className="flex-1"
              onClick={
                !isEditFromCheckPoint
                  ? () => recoverProject()
                  : () => updateCheckpoint()
              }
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <DrawerDS
      title={drawerTitle}
      icon={drawerIcon}
      fitWidth={true}
      content={
        <>
          <div
            className="pt-[24px] w-[424px] h-full relative"
            style={{ display: sendEmail || sendOTP ? "none" : "block" }}
          >
            <TabsDS
              tabs={
                !isEditFromCheckPoint
                  ? [...CapitalTab, ...PersonalTab]
                  : [...CapitalTab]
              }
            />
          </div>
          <div
            className="w-[424px] !pb-[124px] h-full relative"
            style={{ display: sendEmail ? "block" : "none" }}
          >
            <div className="p-[24px]">
              <p
                className="BodyM font-normal text-[#424242] mb-[8px]"
                dangerouslySetInnerHTML={{
                  __html: translate("downloadableFiles.modal.body"),
                }}
              />
              {downloadablePdfs.length > 0 ? (
                <DownloadableFiles downloadablePdfs={downloadablePdfs as any} />
              ) : null}
              <div className="mt-[24px] flex flex-col gap-[16px]">
                <InputDS
                  onKeyDownCapture={handleKeyDown}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  icon="MailIcon"
                  label={translate("modal.sendPdf.label.email")}
                  placeholder={translate("modal.sendPdf.placeholder.email")}
                />
                <TextareaDS
                  label={translate("modal.sendPdf.label.message")}
                  placeholder={translate("modal.sendPdf.placeholder.message")}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  style={{ height: 200 }}
                />
              </div>
            </div>
            <div className="pt-[20px] px-[24px] pb-[24px] fixed bottom-0 flex flex-row gap-[16px] w-full bg-white border-t border-t-[#ECECEC]">
              <ButtonDS
                label="Volver atrás"
                leftIcon="ArrowLeft"
                buttonType="secondary"
                className="flex-1"
                disabled={loading}
                onClick={() => setSendEmail(false)}
              />
              <ButtonDS
                label="Enviar email"
                rightIcon="SendIcon"
                className="flex-1"
                onClick={() => sendEmailDocument(email, text)}
                disabled={!email || !text || loading}
              />
            </div>
          </div>

          <div
            className="w-[424px] !pb-[124px] h-full relative"
            style={{ display: sendOTP ? "block" : "none" }}
          >
            <div className="p-[24px]">
              <p
                className="BodyM font-normal text-[#424242] mb-[8px] list-decimal"
                dangerouslySetInnerHTML={{
                  __html: translate("edit.projects.otp"),
                }}
              />
              {OTP ? (
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(OTP);
                    setShowNotification({
                      message: translate(
                        "dashboard.infoCards.shareModal.copied"
                      ),
                      type: "success",
                      status: true,
                    });
                  }}
                  className="text-[blue] font-bold underline"
                >
                  {OTP}
                </button>
              ) : null}
            </div>
            <div className="pt-[20px] px-[24px] pb-[24px] fixed bottom-0 flex flex-row gap-[16px] w-full bg-white border-t border-t-[#ECECEC]">
              <ButtonDS
                label="Volver atrás"
                leftIcon="ArrowLeft"
                buttonType="secondary"
                className="flex-1"
                disabled={loading}
                onClick={() => setSendOTP(false)}
              />
              <ButtonDS
                label="Copiar link"
                rightIcon="LinkIcon"
                className="flex-1"
                onClick={() => {
                  navigator.clipboard.writeText(OTP);
                }}
                disabled={loading}
              />
            </div>
          </div>
        </>
      }
      onClose={() => setShowModal(false)}
      open={showModal as boolean}
    />
  );
};

const Prices = ({ prices, setSendEmail, generateOTP, modalData }: any) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <div
      className="sticky top-[0px] bg-white p-[24px] z-50"
      style={{
        boxShadow:
          "0px 2px 2px 0px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="text-[var(--primary-color)] flex flex-col p-[24px] bg-[#ecf0ff] rounded-[8px] space-y-[24px]">
        <span className="BodyL editProjects-estimatedTitle">
          {translate("edit.projects.estimatedPrice")}
        </span>
        <div className="flex flex-col gap-[4px] font-medium">
          {prices.isPromoted && prices.beforePromoText ? (
            <div
              className="text-[#7B7B7B] BodyS"
              dangerouslySetInnerHTML={{
                __html: prices.beforePromoText,
              }}
            />
          ) : null}
          <span className="H3 font-medium">
            {Object.keys(prices).length > 0 && prices.monthlyPrice > 0
              ? formatPriceWithoutCurrency(prices.monthlyPrice)
              : translate("view.project.notAvailable")}
            {prices.monthlyPrice > 0 && (
              <span className="BodyS align-top">
                {translate("edit.projects.monthly")}
              </span>
            )}
          </span>
          {prices.isPromoted && prices.afterPromoText ? (
            <div
              className="text-[#7B7B7B] BodyS"
              dangerouslySetInnerHTML={{
                __html: prices.afterPromoText,
              }}
            />
          ) : null}
        </div>
        <div className="flex flex-col gap-[4px] font-medium">
          {prices.isPromoted ? (
            <div
              className="text-[#7B7B7B] BodyS"
              dangerouslySetInnerHTML={{
                __html: translate("dashboard.calculator.withPromo"),
              }}
            />
          ) : null}
          {prices.yearlyPrice > 0 && (
            <span className="H3 font-medium">
              {Object.keys(prices).length > 0
                ? formatPriceWithoutCurrency(prices.yearlyPrice)
                : translate("view.project.notAvailable")}
              <span className="BodyS align-top">
                {translate("edit.projects.yearly")}
              </span>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-[16px] mt-[16px]">
        <ButtonDS
          label="Link para clientes"
          leftIcon="LinkIcon"
          buttonType="tertiary"
          size="32"
          className="flex-1"
          onClick={generateOTP}
          disabled={!EnableOtpButton.includes(modalData.status)}
        />
        <ButtonDS
          label="Documentos"
          leftIcon="FileDownload"
          size="32"
          className="flex-1"
          onClick={() => setSendEmail(true)}
        />
      </div>
    </div>
  );
};

export default EditSQLProject;
