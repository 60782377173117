import "./Projects.scss";
// Controller
import ProjectController from "./controllers/Project.controller";
import { TableLife5 } from "../components/TableLife5/TableLife5";
import EditProjects from "./EditProjects";
import ViewProject from "./ViewProject";
import CustomDateModal from "./CustomDateModal/CustomDateModal";
import OtpModal from "./OtpModal";
import EditSQLProject from "./editProjectsSQL/EditSQLProject";

const Projects = () => {
  const {
    projects,
    projectsInfo,
    broker,
    rol,
    token,
    omniValue,
    setOmniValue,
    fetchProjects,
    showModal,
    setShowModal,
    modalData,
    setModalData,
    recoverProject,
    rangeProps,
    selectRangeDate,
    setSelectRangeDate,
    viewModal,
    setViewModal,
    otpModal,
    setOtpModal,
    otpUrl,
    setOtpUrl,
    viewData,
    setViewData,
    customDateModal,
    setCustomDateModal,
    fetchProjectsByCustomRange,
  } = ProjectController();

  const isSQL = process.env.REACT_APP_DOMAIN_LOCALE === "sql_es";

  return (
    <div className="project__container">
      <TableLife5
        projects={projects}
        projectsInfo={projectsInfo}
        broker={broker}
        token={token}
        omniValue={omniValue}
        setOmniValue={setOmniValue}
        fetchProjects={fetchProjects}
        recoverProject={recoverProject}
        setShowModal={setShowModal}
        setModalData={setModalData}
        rangeProps={rangeProps}
        selectRangeDate={selectRangeDate}
        setSelectRangeDate={setSelectRangeDate}
        viewData={viewData}
        setViewData={setViewData}
        setViewModal={setViewModal}
        setCustomDateModal={setCustomDateModal}
        setOtpModal={setOtpModal}
        setOtpUrl={setOtpUrl}
        otpModal={otpModal}
        otpUrl={otpUrl}
      />
      {isSQL ? (
        <EditSQLProject
          modalData={modalData}
          setShowModal={setShowModal}
          showModal={showModal}
          fetchProjects={fetchProjects}
        />
      ) : (
        <EditProjects
          disabledButton={false}
          modalData={modalData}
          recoverProject={recoverProject}
          setShowModal={setShowModal}
          showModal={showModal}
          token={token}
          broker={broker}
          fetchProjects={fetchProjects}
        />
      )}
      <ViewProject
        viewData={viewData}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />

      <OtpModal otpModal={otpModal} otpUrl={otpUrl} setOtpModal={setOtpModal} />
      <CustomDateModal
        open={customDateModal}
        setOpen={setCustomDateModal}
        submit={fetchProjectsByCustomRange}
      />
    </div>
  );
};

export default Projects;
