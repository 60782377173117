import React, { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import apiVelogicaLogin from "../../../api/request/Integrations/apiVelogicaLogin";
import { I18nContext } from "../../../contexts/i18n.context";
import { ButtonDS } from "get-life-storybook-ts";

export interface VelogicaI {
  question: any;
}
const VelogicaQuestion: React.FC<VelogicaI> = ({ question }): JSX.Element => {
  const {
    leadId,
    token,
    setLoading,
    setTokenVelogica,
    setQuestion,
    tokenVelogica,
    DOMAIN,
  } = useContext(ContextGetlife);

  const [confirmEdit, setConfirmEdit] = useState<boolean>(false);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const fetchVelogica = async () => {
    try {
      const data = await apiVelogicaLogin(leadId, token);
      setTokenVelogica(data.access_token);
      setQuestion(question.questionId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchVelogica();
  }, []);

  if (DOMAIN === "sql_es") {
    if (confirmEdit && question.value !== "answered") {
      return (
        <>
          {
            <iframe
              width={"100%"}
              height={"450px"}
              src={`${process.env.REACT_APP_VELOGICA_IFRAME}${tokenVelogica}`}
              title="velogica"
            />
          }
        </>
      );
    } else if (question.value === "answered") {
      return <p>{translate("question.velogica.complete")}</p>;
    } else {
      return (
        <div className="flex flex-col w-full border-[2px] border-[#5D5AE9] rounded-lg p-4">
          <div className="flex flex-col">
            <span className="BodyM font-bold text-[#3430E3]">
              {translate("velogica.alert.title")}
            </span>
            <span className="BodyS font-medium text-[#686868]">
              {translate("velogica.alert.subtitle")}
            </span>
          </div>
          <div className="flex flex-row-reverse">
            <ButtonDS
              label={translate("landing.calculator.buttonContinue")}
              rightIcon="ArrowGoRightIcon"
              size="32"
              onClick={() => setConfirmEdit(true)}
            />
          </div>
        </div>
      );
    }
  } else {
    if (question.value !== "answered") {
      return (
        <>
          {
            <iframe
              width={"100%"}
              height={"450px"}
              src={`${process.env.REACT_APP_VELOGICA_IFRAME}${tokenVelogica}`}
            />
          }
        </>
      );
    } else {
      return <p>{translate("question.velogica.complete")}</p>;
    }
  }
};

export default VelogicaQuestion;
